import { useTranslate } from 'src/providers';

const ClusterPin = ({
  count,
  onClick,
}: {
  count: number;
  onClick: () => void;
}) => {
  const translate = useTranslate();
  return (
    <button
      type="button"
      onClick={onClick}
      className="h-32 w-32 rounded-full bg-always-black flex items-center justify-center hover:scale-zoomed transition-transform"
      aria-label={
        translate('global.general.mapClusterPinAriaLabel') ||
        'Volvo retailers map pin'
      }
    >
      <p className="dl-font-14 font-medium text-always-white dl-font-volvo">
        {count}
      </p>
    </button>
  );
};

export default ClusterPin;
