import { Track, TrackingProvider } from '@volvo-cars/tracking';
import { useStore, useTranslate } from 'src/hooks';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { State } from 'src/providers/StoreProvider';

type NavigationButton = {
  eventLabel: string;
  view: State['view'];
  text: string;
};

export const Bottombar = () => {
  const { dispatch, view } = useStore();
  const translate = useTranslate();

  const navButtons: NavigationButton[] = [
    {
      eventLabel: 'map-view',
      view: 'map',
      text: translate('global.general.mapViewTabLabel') || 'Map view',
    },
    {
      eventLabel: 'list-view',
      view: 'list',
      text: translate('global.general.listViewTabLabel') || 'List view',
    },
  ];

  return (
    <TrackingProvider eventCategory={TRACKING_CATEGORY}>
      <div
        aria-label="Page navigation"
        className="flex lg:hidden fixed bottom-0 w-full bg-secondary z-navigation"
        id=":r0:"
        role="tablist"
      >
        {navButtons.map((button, index) => (
          <Track
            eventLabel={button.eventLabel}
            eventAction="toggle|click"
            key={index}
          >
            <button
              type="button"
              aria-controls={`:r0:panel${index}`}
              className="p-8 font-medium flex-grow selected:border-primary border-b-2 transition-colors tap-area"
              id={`:r0:tab${index}`}
              role="tab"
              onClick={() => {
                dispatch({ type: 'SET_VIEW', payload: button.view });
              }}
              aria-selected={button.view === view}
            >
              {button.text}
            </button>
          </Track>
        ))}
      </div>
    </TrackingProvider>
  );
};
