import MapErrorBoundary from '../MapErrorBoundary';
import { Map } from './Map';

type MapPositionedProps = {
  view: 'map' | 'list';
};

const MapPositioned = ({ view }: MapPositionedProps) => (
  <>
    {/* 4084302 With only redesign and desktop not caring about view, this can be rendered only once */}
    {/* Rendering map with two statements as pins are not loading correctly on tablet view if map is hidden onload */}
    {view === 'map' && (
      <div className="h-full -mt-8 md:mt-0 lg:hidden">
        <MapErrorBoundary>
          <Map />
        </MapErrorBoundary>
      </div>
    )}
    <div className="h-full until-lg:hidden">
      <MapErrorBoundary>
        <Map />
      </MapErrorBoundary>
    </div>
  </>
);

export default MapPositioned;
