import React, { useEffect } from 'react';
import {
  useRetailers,
  useSelectorSettings,
  useStore,
  useTranslate,
} from 'src/hooks';
import MiniCard from 'src/features/dealer-picker/retailerCard/MiniCard';

type RetailerListProps = {
  listRef: React.RefObject<HTMLUListElement>;
  scrollToCard: (ref: React.RefObject<HTMLLIElement>) => void;
  scrollToTop: () => void;
};
export const RetailersList = ({
  listRef,
  scrollToCard,
  scrollToTop,
}: RetailerListProps) => {
  const { retailers } = useRetailers();
  const { retailersListVisible } = useStore();
  const { useSelector, onRetailerSelect } = useSelectorSettings();
  const translate = useTranslate();

  // Scroll when a new search or sorting has been done
  useEffect(() => {
    scrollToTop();
  }, [retailers, scrollToTop]);

  if (!retailers || !retailersListVisible) return null;
  return (
    <ul
      className="flex-col gap-16 px-24 lg:px-0 xl:px-0 relative"
      ref={listRef}
    >
      {retailers?.map(
        (retailer, key) =>
          retailer && (
            <MiniCard
              retailer={retailer}
              scrollToCard={scrollToCard}
              key={
                key +
                (retailer?.latitude.toString() || '') +
                (retailer?.longitude.toString() || '') +
                retailer?.addressLine1
              }
              ctaText={
                useSelector
                  ? translate('global.retailer.selectRetailer') ||
                    'Select retailer'
                  : undefined
              }
              onCtaClick={useSelector ? onRetailerSelect : undefined}
            />
          ),
      )}
    </ul>
  );
};
