import { RefObject, useCallback, useRef } from 'react';
import { useBreakpoints, useTopbarHeight } from '@vcc-www/hooks';

type ScrollProps = {
  listRef: RefObject<HTMLUListElement>;
  listContainerRef: RefObject<HTMLDivElement>;
  scrollToCard: (ref: RefObject<HTMLLIElement>) => void;
  scrollToTop: () => void;
};

export function useRetailerScroll(
  scrollBehaviour: ScrollOptions['behavior'],
): ScrollProps {
  /*useBreakpoints as the scrolling behaviour depends on screen size, which can not be set in CSS only*/
  const { fromL } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const topbarHeight = useTopbarHeight();
  const listRef = useRef<HTMLUListElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);

  const scrollToCard = (ref: RefObject<HTMLLIElement>) => {
    // To only trigger scroll for visible ref
    if (!ref.current || !ref.current.clientHeight) return;
    if (fromL) {
      if (!listContainerRef.current) return;
      listContainerRef.current.scrollTo({
        top: ref.current.offsetTop,
        behavior: scrollBehaviour,
      });
    } else {
      if (!listRef.current) return;
      const parentOffset = listRef.current?.offsetTop || 0;
      ref.current.style.scrollMargin = `${parentOffset + topbarHeight}px`;
      ref.current.scrollIntoView({
        block: 'start',
        behavior: scrollBehaviour,
      });
    }
  };

  const scrollToTop = useCallback(() => {
    const scrollOptions: ScrollToOptions = {
      top: 0,
      behavior: 'instant',
    };
    listContainerRef.current?.scrollTo(scrollOptions);
    window.scrollTo(scrollOptions);
  }, []);

  return {
    listRef,
    listContainerRef,
    scrollToCard,
    scrollToTop,
  };
}
