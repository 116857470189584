import { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { RetailerNameDistance } from '@vcc-package/retailer-selector';
import { useTracker } from '@volvo-cars/tracking';
import { Retailer } from 'src/types/retailer';
import { useFormattedRetailerCardInfo } from 'src/hooks/useFormattedRetailerCardInfo';
import { useOffset, useSelectorSettings, useStore } from 'src/hooks';
import {
  DEFAULT_SELECTED_RETAILER_ZOOM,
  updateMapPanBoundsZoom,
} from 'src/utils/mapUtils';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { getDealerId } from 'src/utils/volvoTrackingHelpers';
import { PhoneNumber } from './PhoneNumber';
import RetailerAddress from './RetailerAddress';
import styles from './MiniCard.module.css';

type MiniCardProps = {
  retailer: Retailer;
  ctaText?: string;
  onCtaClick?: (retailer: Retailer) => void;
  scrollToCard: (ref: React.RefObject<HTMLLIElement>) => void;
};

const MiniCard = ({
  retailer,
  ctaText,
  onCtaClick,
  scrollToCard,
}: MiniCardProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const track = useTracker();
  const { useSelector } = useSelectorSettings();
  const { dispatch, map } = useStore();
  const offset = useOffset();

  const { phoneNumbers, name, latitude, longitude } = retailer;
  const { distance, addressLines, isSelectedRetailer } =
    useFormattedRetailerCardInfo(retailer);

  useEffect(() => {
    if (isSelectedRetailer) {
      scrollToCard(ref);
    }
  }, [isSelectedRetailer, scrollToCard]);

  const handleRetailerClick = useCallback(() => {
    if (!latitude || !longitude || !retailer) return;
    updateMapPanBoundsZoom(map, {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
      zoom: DEFAULT_SELECTED_RETAILER_ZOOM,
      offset,
    });
    dispatch({ type: 'SET_SELECTED_RETAILER', payload: retailer });
    dispatch({ type: 'SET_EXPANDED_CARD_VISIBLE', payload: true });

    track.customEvent({
      eventAction: 'card|select',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'retailer card',
      dealerId: getDealerId(retailer),
      dealerInfo: retailer?.name ?? undefined,
    });
  }, [map, latitude, longitude, retailer, dispatch, track, offset]);

  const handleCtaClick = (e: MouseEvent) => {
    if (!onCtaClick) return;
    e.stopPropagation();
    onCtaClick(retailer);
  };
  const commonClassName = `text-start w-full bg-always-white items-stretch flex-col p-24 gap-16 rounded border ${isSelectedRetailer ? 'border-accent-blue' : 'border-ornament'}`;

  const content = (
    <>
      <RetailerNameDistance name={name} distance={distance} />
      <RetailerAddress name={name} addressLines={addressLines} />
      <PhoneNumber
        name={name}
        phoneNumber={phoneNumbers.main}
        textColour="black"
      />
      {ctaText && (
        <div className="border-t border-ornament flex-col items-center">
          <button
            className="button-outlined mt-24"
            type="button"
            onClick={handleCtaClick}
          >
            {ctaText}
          </button>
        </div>
      )}
    </>
  );

  return (
    <li ref={ref} data-testid="dealer:miniCard">
      {/* When StoreProvider is moved and intialProps can be different on selector vs locator, we want this component to take in onClick, and this does not have to be dependent on useSelector */}
      {useSelector ? (
        <div className={`${commonClassName}`} aria-current={isSelectedRetailer}>
          {content}
        </div>
      ) : (
        <button
          className={`${styles.buttonCard} ${commonClassName}`}
          onClick={handleRetailerClick}
          type="button"
          aria-current={isSelectedRetailer}
        >
          {content}
        </button>
      )}
    </li>
  );
};
export default MiniCard;
