import { useEffect, useState } from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { StoreContextValue, useTranslate } from 'src/providers';
import styles from './SinglePin.module.css';

const SinglePin = ({
  visible,
  lat,
  lng,
  retailer,
  onClick,
}: {
  visible: boolean;
  lat: number;
  lng: number;
  retailer: StoreContextValue['selectedRetailer'];
  onClick: () => void;
}) => {
  const [show, setShow] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    setShow(false);
    const timeout =
      visible &&
      setTimeout(() => {
        setShow(true);
      }, 200);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [lat, lng, visible]);

  return (
    <button
      type="button"
      aria-label={
        translate('global.general.mapPinAriaLabel', {
          retailerName: retailer?.name,
        }) || `${retailer?.name} map pin`
      }
      onClick={onClick}
      className={`${styles.pin} rounded-full bg-always-black h-32 w-32 flex items-center justify-center ${show ? 'scale-100' : 'dl-scale-0'} hover:scale-zoomed`}
    >
      <Icon
        alt=""
        color="always-white"
        filled
        icon="volvo-iron-mark"
        size={16}
      />
    </button>
  );
};

export default SinglePin;
